<div class="footer surface-section p-4 shadow-2 border-round mt-3 grid">
  <div class="hidden md:block col">
    <h4>Rolling Saves</h4>
    <img
      src="android-chrome-192x192.png"
      alt="Footer Logo"
      class="border-round"
    />
  </div>

  <div class="col">
    <h4>Browse</h4>
    <ul class="list-none p-0 m-0 align-items-center font-medium mb-3">
      <li>
        <a
          class="text-500 no-underline line-height-3 text-secondary cursor-pointer"
          (click)="navigate('')"
          >Home</a
        >
      </li>
      <li>
        <a
          class="text-500 no-underline line-height-3 text-secondary cursor-pointer"
          (click)="navigate('learn-more')"
          >Learn More</a
        >
      </li>
      <li>
        <a
          class="text-500 no-underline line-height-3 text-secondary cursor-pointer"
          (click)="navigate('get-involved')"
          >Get Involved</a
        >
      </li>
      <li>
        <a
          class="text-500 no-underline line-height-3 text-secondary cursor-pointer"
          (click)="navigate('events')"
          >Events</a
        >
      </li>
    </ul>
  </div>
  <div class="col">
    <h4>Contact Us</h4>
    <ul class="list-none p-0 m-0 align-items-center font-medium mb-3">
      <li>
        <a
          href="mailto:rob.talbott@gmail.com?subject=RollingSaves General Inquiry"
          target="_blank"
          rel="noopener noreferrer"
          class="font-bold"
        >
          General Inquiries
        </a>
      </li>
    </ul>
  </div>
</div>
