import { Component } from '@angular/core';
import { ButtonDirective } from 'primeng/button';

@Component({
  selector: 'app-get-involved',
  standalone: true,
  imports: [ButtonDirective],
  templateUrl: './get-involved.component.html',
  styleUrl: './get-involved.component.scss',
})
export class GetInvolvedComponent {
  mailToSubject = 'RollingSaves Volunteer Opportunity';
}
