import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RollingSavesApiService {
  constructor(private http: HttpClient) {}

  buildPath(path: string): string {
    return 'api/' + path;
  }

  async helloWorld(): Promise<string> {
    const res = await firstValueFrom(
      this.http.get<string>(this.buildPath('helloWorld'))
    );
    console.log(res);
    return res;
  }
}
