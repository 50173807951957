<div class="container get-involved grid">
  <div class="col">
    <h1>We are looking for Volunteers!</h1>
    <h3>
      Rolling Saves is planning our next wargaming charity event. We are seeking
      sponsors, volunteers, and players!
    </h3>
    <h4>
      Please help us make this event happen by assisting us with any of the
      following
    </h4>
    <body class="grid outreach">
      <section class="col">
        <h4>Events</h4>
        <ul>
          <li>Running an event</li>
          <li>Lending boards, terrain, and models</li>
        </ul>
        <a
          href="mailto:rob.talbott@gmail.com?subject=RollingSaves Events Volunteering"
          target="_blank"
          rel="noopener noreferrer"
          class="p-button font-bold"
        >
          Contact Rob
        </a>
      </section>
      <section class="col">
        <h4>Partnership</h4>
        <ul>
          <li>Providing prize support</li>
          <li>Assisting or providing promotional materials</li>
        </ul>
        <a
          href="mailto:rob.talbott@gmail.com?subject=RollingSaves Partnership Inquiry"
          target="_blank"
          rel="noopener noreferrer"
          class="p-button font-bold"
        >
          Contact Rob
        </a>
      </section>
      <section class="col">
        <h4>Tech</h4>
        <ul>
          <li>Assisting with website development</li>
          <li>Providing or assisting with digital assets</li>
        </ul>
        <a
          href="mailto:darons23@gmail.com?subject=RollingSaves Tech Volunteering"
          target="_blank"
          rel="noopener noreferrer"
          class="p-button font-bold"
        >
          Contact Daron
        </a>
      </section>
    </body>
  </div>
</div>
