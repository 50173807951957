import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LearnMoreComponent } from './learn-more/learn-more.component';
import { GetInvolvedComponent } from './get-involved/get-involved.component';
import { EventsComponent } from './events/events.component';
import { SandboxComponent } from './sandbox/sandbox.component';

export const routes: Routes = [
  {
    path: 'learn-more',
    title: 'Learn More - Rolling Saves',
    component: LearnMoreComponent,
  },
  {
    path: 'get-involved',
    title: 'Get Involved - Rolling Saves',
    component: GetInvolvedComponent,
  },
  {
    path: 'events',
    title: 'Events - Rolling Saves',
    component: EventsComponent,
  },
  {
    path: 'sandbox',
    title: 'Sandbox',
    component: SandboxComponent,
  },
  {
    path: '',
    title: 'Rolling Saves',
    component: HomeComponent,
  },
];
