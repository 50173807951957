import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { ListboxModule } from 'primeng/listbox';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [ListboxModule, ButtonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  constructor(private readonly router: Router) {
    //
  }

  navigate = (path: string) => {
    this.router.navigate([path]);
  };
}
