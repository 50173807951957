import { Component, OnInit } from '@angular/core';
import { JsonPipe } from '@angular/common';
import { RollingSavesApiService } from '../shared/api.service';

@Component({
  selector: 'app-sandbox',
  standalone: true,
  imports: [JsonPipe],
  templateUrl: './sandbox.component.html',
  styleUrl: './sandbox.component.scss',
})
export class SandboxComponent implements OnInit {
  apiResponse!: any;

  constructor(private readonly api: RollingSavesApiService) {
    //
  }
  ngOnInit(): void {
    this.fetch();
  }

  async fetch() {
    this.apiResponse = await this.api.helloWorld();
  }
}
