<div class="container events grid">
  <div class="col">
    <h1>Rolling Saves Events</h1>
    <h2>
      Event plans are still works-in-progress, but here's the current slate:
    </h2>
  </div>
  <section class="grid">
    <div class="col">
      <h3>Warcry Open Play</h3>
      <p>
        Structured open play goodies for participating and raffled prizes at the
        end.
      </p>
      <p>
        Some extra warbands and reference documents and cards can be provided to
        those who don't have teams.
      </p>
    </div>
    <div class="col">
      <h3>Kill Team Open Play</h3>
      <p>
        Structured open play goodies for participating and raffled prizes at the
        end.
      </p>
      <p>
        Some extra warbands and reference documents and cards can be provided to
        those who don't have teams.
      </p>
    </div>
    <div class="col">
      <h3>Age of Sigmar Spearhead Open Play</h3>
      <p>
        Structured open play goodies for participating and raffled prizes at the
        end.
      </p>
    </div>
  </section>
</div>
