import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonDirective } from 'primeng/button';
import { ImageModule } from 'primeng/image';

@Component({
  selector: 'app-home',
  standalone: true,
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  imports: [ButtonDirective, ImageModule],
})
export class HomeComponent {
  constructor(private readonly router: Router) {
    //
  }

  navigate = (route: string) => {
    this.router.navigate([route]);
  };
}
