<div class="home container grid grid-nogutter surface-section text-800">
  <img
    src="android-chrome-512x512.png"
    alt="Image"
    class="block md:hidden col-12 border-round"
  />
  <section class="col">
    <h1 class="hidden md:block text-6xl font-bold mb-1">Rolling Saves</h1>
    <h2 class="text-4xl text-primary font-bold mb-3">
      Supporting charity one handful of dice at a time
    </h2>
    <p class="mt-0 mb-4 text-700 line-height-3">
      Join us for a fun-focused charity Wargaming event in the Twin Cities.
    </p>

    <p-button
      pButton
      pRipple
      label="Learn More"
      type="button"
      class="col mr-3 p-button-raised"
      (click)="navigate('learn-more')"
    ></p-button>
    <p-button
      pButton
      pRipple
      label="Get Involved"
      type="button"
      class="col p-button-outlined"
      (click)="navigate('get-involved')"
    ></p-button>
  </section>
  <div class="hidden md:block col-12 md:col overflow-hidden">
    <img
      src="assets/images/rolling_saves_ai_gen_optimized.webp"
      alt="Image"
      class="hero-image"
    />
  </div>
</div>
