import { Component } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FieldsetModule } from 'primeng/fieldset';

@Component({
  selector: 'app-learn-more',
  standalone: true,
  imports: [FieldsetModule],
  providers: [provideAnimations()],
  templateUrl: './learn-more.component.html',
  styleUrl: './learn-more.component.scss',
})
export class LearnMoreComponent {
  //
}
