<div class="container learn-more grid">
  <section class="col">
    <h2>
      Wargamers and Volunteers with a hankering for mental health charity
      fundraising
    </h2>
    <p-fieldset class="blurb" legend="Our Goal">
      <p class="m-0">
        Our goal is to use to organize fun wargaming event experiences in an
        effort to raise funds for local mental health organizations.
      </p>
    </p-fieldset>
    <p-fieldset class="blurb" legend="Our History">
      <p class="m-0">
        Our event started as a simple "Adeptigone" event in March 2024 after a
        few of us couldn't make it out to Adepticon proper
      </p>
      <p>
        At that event, we raised over $300 with lots of help from local game
        stores in the form of prize support, and significant prize support, lots
        of personal effort from the organizers, and no budget whatsoever.
      </p>
      <p>
        We intend to keep that spirit alive with an even bigger turnout and
        amount of money raised!
      </p>
      <p></p>
    </p-fieldset>
  </section>
</div>
