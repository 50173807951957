<div class="app-header surface-section px-4 py-5 md:px-6 lg:px-8 mb-3">
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
        <a class="text-500 no-underline line-height-3 cursor-pointer" (click)="navigate('')">Home</a>
    </li>
    <li>
        <a class="text-500 no-underline line-height-3 cursor-pointer" (click)="navigate('learn-more')">Learn More</a>
    </li>
    <li>
        <a class="text-500 no-underline line-height-3 cursor-pointer" (click)="navigate('get-involved')">Get Involved</a>
    </li>
    <li>
        <a class="text-500 no-underline line-height-3 cursor-pointer" (click)="navigate('events')">Events</a>
    </li>
  </ul>
</div>